import React from "react"
import { Box, Grid, Typography } from "@mui/material"

export const AgreementTerms: React.FC = () => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="h6">
            <strong>I AGREE TO:</strong>
          </Typography>
          <Box component="ol">
            <Box component="li">
              Actively participate in promotional weekly meetings and a minimum
              of five 10-minute presentations about my business during any
              12-month period.
            </Box>
            <Box component="li">
              Use my best efforts to generate referrals for other members.
              Colleen Moody Valadez, CEO, has the right to drop any member that
              does not practice good business ethics.
            </Box>
            <Box component="li">
              Not miss more than 12 absences in a 12-month period, unless agreed
              by special circumstances.
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" component="h6">
            <strong>TERMS:</strong>
          </Typography>
          <Box component="ul">
            <Box component="li">
              All fees paid are non-refundable. Membership may be transferred to
              another chapter with prior consent from the CEO.
            </Box>
            <Box component="li">
              Membership is $365.00 / year. Or, $1.00 a day! Please pay online
              monthly in the amount of $31.00. There are no enrollment fees. You
              will be invoiced by bookkeeper Norma Mancini. We encourage monthly
              payments instead of yearly payments please.
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
